@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
.main-nav {
    width: 100%;
    position: fixed !important;
    top: 0;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(15, 17, 19, 0.04);
    z-index: 99;
    width: 100%;
    padding: 8px 40px;

    .main-nav-flex{
        display: flex;
        justify-content: space-between;

    }
    .main-nav-notification-popup{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .notification-bell-div{
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(192, 192, 192, 0.25);
        border-radius: 20px;
        padding: 8px;
        margin-right: 30px;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }


    .main-nav-user-profile-popover{
        background: #DEDEDE;
        box-shadow: 0px 4px 16px rgba(192, 192, 192, 0.25);
        border-radius: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
        margin-right: 10px;
        cursor: pointer;
    }
    .main-nav-user-profile-popover h1{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        margin-top: 7px;
    }
    .popover-card{
        background: #FFFFFF;
        padding: 10px;
    }
    .main-nav-user-details{
        display: flex;

    }

    .main-nav-user-name{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #000000;
    }
    .main-user-view-profile{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        opacity: 0.5;
    }

    .main-nav-user-inner-popover{
        padding: 10px;
    }



.nav-links-menu-btn {
    display: none;
&.inactive {
     pointer-events: none;
 }
}
.links-wrapper {
    display: flex;
    align-items: center;
ul {
    display: flex;
    list-style: none;
// align-items: center;
// align-items: center;
li {
    margin-right: 28px;
a {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    color: var(--primary--dark);
}

}

@media screen and (max-width: 1150px) {
    li {
        margin-right: 40px;
    }
}
@media screen and (max-width: 1000px) {
    li {
        margin-right: 20px;
    }
}
}
button {
    padding: 16px 40px;
    background: linear-gradient(135deg, #ff9494 14.64%, #ff7070 85.36%);
}
}

@media screen and (max-width: 1070px) {

    .main-nav-flex{
        padding: 0px;
    }


    .links-wrapper {
        display: none;
    }
    .nav-links-menu-btn {
        display: block;
        position: relative;
        bottom: 44px;
        width: fit-content;
        right: 25px;
    img {
        height: 20px;
        width: 20px;

    }

&.close {
    img {
        filter: invert(0.9);
    }
}
}
.sm-links-wrapper {
    background: #ffffff;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 64px;
a {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    color: var(--primary--dark);
    padding: 12px;
}
button {
    margin-top: 12px;
    font-size: 12px;
    line-height: 14px;
}
}
}
}

// @media screen and (min-width:1200px) and (max-width:1380px){
//   a{
    //     font-size: 14px !important;
    //   }
// }

@media screen and (min-width:1071px) and (max-width:1200px){
    a{
        font-size: 8px !important;
    }
}

.main-nav-user-inner-popover{
    width: 47.92px;
    height: 47.92px;
    text-align: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #F1F2F4;
    border-radius: 42.7882px;
    padding-top: 10px;
}
.main-nav-user-details{
    display: flex;
    padding: 10px;
    align-items: center;
}
.main-nav-user-details-dropdown{
    display: flex;
    cursor: pointer;
    padding: 10px;
}
.main-nav-user-name-popover{
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 27.3844px;
    line-height: 21px;
    color: #FFFFFF;
    padding-top: 3px;
}

.main-nav-user{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 10px;
}

.main-user-view-profile{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #000000;
    opacity: 0.5;
    margin-left: 10px;
}

.main-nav-popover-box{
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
    background: white;
}



.main-nav-inner-popover hr{
    opacity: 0.2;
    border: 1px solid #000000;
}

@media screen and (max-width: 1023px) {
    .main-nav-inner-popover{
        width: 100% !important;
    }
    .overflow-notification-box{
        position: absolute;
        top: 290px !important;
    }
    .main-nav-popover-box{
        width: 100% !important;
    }
    .main-nav-user-profile-popover h1{
        margin-top: 2px !important;
        font-size: 16px !important;
    }
}

a{
    text-decoration: none;
}

.c-h-notification{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-h-notification h1{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.c-h-notification p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #FF7070;
}

.c-h-notification-msg{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.c-h-notification-msg-flex{
    display: flex;
    align-items: center;
}

.c-h-notification-msg-flex h2{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-left: 10px;
}

.c-h-notification-msg-flex p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    opacity: 0.7;
    margin-left: 10px;
}
.c-h-notification-msg h3{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.msg-row{
    margin-top: 10px;
}

.c-h-notification-box{
    width: 26px;
    height: 26px;
    background: linear-gradient(116.03deg, #FF9494 16.4%, #FF7070 83.6%);
    border-radius: 21px;
}

.c-h-notification-box p{
    text-align: center;
    padding-top: 7px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #FFFFFF;
}
.overflow-notification-box::-webkit-scrollbar{
    display: none !important;
}

#simple-popover{
    z-index: 1000;
}

@media screen and (max-width: 1239px) {
    .main-nav{
        height: 65px;
        .nav-links-menu-btn{
            position: relative;
            bottom: 33px !important;
        }
        .main-nav-logo{
            width: 50px;
            height: 50px;
        }
        .main-nav-user-profile-popover{
            width: 30px !important;
            height: 30px !important;
            img{
                width: 30px !important;
                height: 30px !important;
            }
        }
        .notification-bell-div{
            width: 30px !important;
            height: 30px !important;
        }
    }

}

.main-nav-logo{
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.moderator-logout-button{
    background: linear-gradient(135deg,#ff9494 14.64%,#ff7070 85.36%);
    padding: 5px 10px;
    width: fit-content;
    border: none;
    outline: none;
    border-radius: 8px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
}

.admin-moderator{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 14px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 540px) {
    .admin-moderator{
        font-family: "Manrope";
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 14px;
        color: #000000;
    }
    .moderator-logout-button{
        width: 80px;
        height: 30px;
        padding: 0px;
    }
}

.popover-modal::-webkit-scrollbar{
    display: none;
}